import type { ActionFunctionArgs } from '@remix-run/node'
import { json } from '@remix-run/node'
import { Link, useFetcher } from '@remix-run/react'
import type { CookiePolicy } from '~/cookies'
import { cookiePolicy } from '~/cookies'

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.clone().formData()
  const cookieHeader = request.headers.get('Cookie')
  const cookie: CookiePolicy = (await cookiePolicy.parse(cookieHeader)) || {}
  const acceptCookie = formData.get('accept-cookie')

  if (acceptCookie !== null) {
    cookie.accepted = acceptCookie === 'true'
    return json(
      { success: true },
      {
        headers: {
          'Set-Cookie': await cookiePolicy.serialize(cookie)
        }
      }
    )
  }
  return json({ success: true })
}

export function CookiePolicyBanner() {
  const cookiePolicyFetcher = useFetcher<typeof action>()

  if (cookiePolicyFetcher.data?.success) {
    return null
  }
  return (
    <div className="fixed bottom-0 z-50 w-full bg-white bg-opacity-90 py-4">
      <div className="flex flex-col items-center justify-center gap-3 px-4 lg:flex-row">
        <span className="text-center">
          This site uses cookies. Find out more about the cookies we are using,{' '}
          <Link className="underline" target="_blank" to="/cookie-policy">
            click here
          </Link>
          .
        </span>

        <cookiePolicyFetcher.Form method="post" action="/fullstack-components/cookie-policy" className="flex gap-4">
          <button name="accept-cookie" value="true" type="submit" className="btn-secondary btn">
            Accept
          </button>
          <button name="accept-cookie" value="false" type="submit" className="btn-secondary btn">
            Decline
          </button>
        </cookiePolicyFetcher.Form>
      </div>
    </div>
  )
}
